<template lang="pug">
.vh100.d-flex-1.flex-wrap.justify-content-start.editor-wrapper(:class="classes")
  popper(
    v-if="popoverVisible"
    trigger="click"
    :force-show="popoverVisible"
    :options="{ placement: 'left' }"
  )
    .popper.brand-tooltip.brand-tooltip-light.brand-tooltip-left.ml-n1(
      v-bind:style="{ marginTop: `${top}px`, marginLeft: `${left}px !important` }"
    )
      component(:is="popoverComponentName" @close="closePopover")
    div(slot="reference")
  MarginPopover(
    ref="marginPopover"
    v-if="marginPopover"
    @close="onClose"
    :position="marginPopover"
  )
  EditorGlobalSettings(
    :isClosed.sync="globalSettingsClosed"
    :saveType="saveType"
    :key="sidebarKey"
  )
  .popup.full(ref="popup" :class="{ 'mobile-view': mobilePreview }")
    .om-workspace-container.hide-text-selection(ref="workspaceContainer")
      .custom-editor-overlay.hide-text-selection(v-show="showOverlay")
      .left-column-toggle(@click="toggleGlobalSettings" v-if="!globalSettingsClosed")
        fa-icon(variant="fa-angle-left")
      .om-workspace(ref="workspace" :class="{ 'om-mobile-frame-new': mobilePreview }")
        .d-flex.mobile-size-chooser(v-if="mobilePreview")
          .d-flex.flex-column.mobile-info
            om-body-text.px-2.text-right.mobile-type(bt400xs) {{ getMobileType }}
            om-body-text.px-2.text-right.mobile-type(bt400xs) {{ getMobileDimension }}
          .mobile-size-select(v-if="mobilePreview")
            om-select#dimensions(
              v-model="selectedSize"
              :options="mobilePhoneType"
              size="small"
              :iconOnly="true"
              :iconType="`mobile-android`"
            )
            om-tooltip(
              placement="bottom"
              :arrow="false"
              small
              :class="{ 'empty-tooltip': disabledResizeButton }"
            )
              template(v-if="!disabledResizeButton")
                span(v-if="isResized") {{ $t('mobileResizer.zoomIn') }}
                span(v-else) {{ $t('mobileResizer.viewWholeScreen') }}
              template(slot="trigger")
                om-button(
                  secondary
                  :icon="iconName"
                  iconOnly
                  @click="transformIframe"
                  :disabled="disabledResizeButton"
                )
        iframe#workspaceFrame(
          ref="phoneFrame"
          src="/workspace.html?t=5000"
          @load="iframeLoaded"
          v-show="!loaderShown"
          :class="{ 'om-mobile-workspace': mobilePreview }"
        )
        .iframe-fake(data-target=".om-overlay .om-canvas-content")
      editor-pages(:productTourVisible="productTourVisible")
  EditorComponentSettings(:saveType="saveType" sidebarKey="component-settings-sidebar")
  .om-palette-colors
  custom-editor-box(@overlay="overlayHandler")
  page-rename-modal(@setPages="setPages")
  link(
    href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=latin-ext"
    rel="stylesheet"
  )
  DeviceSelectorList
  new-campaign(@domainUpdated="onDomainUpdated")
  portal(to="root")
    custom-font-upload-v2
    custom-font-upload
    product-tour-success
    delete-fields
    manage-field
    coupon-creation
    input-validations
    feature-for-feedback
    add-new-style
    edit-custom-style
    delete-custom-style
  portal(to="root")
    image-manager(v-if="imageManagerVisible")
    image-manager-v2(v-if="imageManagerV2Visible")
    image-cropper(v-if="imageCropperVisible")
    new-campaign(@domainUpdated="onDomainUpdated")
    ImageReplace
  product-tour(v-if="productTourVisible")
</template>
<script>
  import { track } from '@/services/xray';
  import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
  import { getQueryVariable, createPaletteStyleElement, generatePalette } from '@/editor/util';
  import { client as apollo } from '@/editor/apollo';
  import { debounce as _debounce, get as _get } from 'lodash-es';
  import gql from 'graphql-tag';
  import History from '@/editor/services/history';
  import DesktopSvg from '@/editor/components/svg/DesktopSvg';
  import MobileSvg from '@/editor/components/svg/MobileSvg';
  import EditorGlobalSettings from '@/editor/components/sidebar/EditorGlobalSettings.vue';
  import hotkeys from 'hotkeys-js';
  import upgradePlanModal from '@/mixins/upgradePlanModal';
  import PageRenameModal from '@/editor/components/modals/PageRename.vue';
  import { MockLayouts } from '@/editor/components/MockSite.vue';
  import EditorPages from '@/editor/components/EditorPages.vue';
  import { loadAdditionalFonts } from '@/utils/fontLoad';
  import { frame, frameStore } from '@/util';
  import NewCampaign from '@/components/Modals/NewCampaign.vue';
  import localStorage from '@/mixins/localStorage';
  import EditorComponentSettings from '@/editor/components/sidebar/EditorComponentSettings.vue';
  import CustomFontUpload from '@/editor/components/modals/CustomFontUpload.vue';
  import CustomFontUploadV2 from '@/editor/components/modals/CustomFontUploadV2.vue';
  import ManageField from '@/editor/components/modals/formManager/ManageField.vue';
  import CouponCreation from '@/editor/components/modals/CouponCreation.vue';
  import InputValidations from '@/editor/components/modals/formManager/InputValidations.vue';
  import DeleteFields from '@/editor/components/modals/formManager/DeleteFields.vue';
  import AddNewStyle from '@/editor/components/modals/formManager/AddNewStyle.vue';
  import EditCustomStyle from '@/editor/components/modals/formManager/EditCustomStyle.vue';
  import DeleteCustomStyle from '@/editor/components/modals/formManager/DeleteCustomStyle.vue';
  import DeviceSelectorList from '@/editor/components/DeviceSelectorList.vue';
  import popover from '@/editor/mixins/popover';
  import { getContextProperties } from '@/services/userInteractionTracker/properties';
  import { ELEMENTS } from '@om/template-properties/src/propertyHelper';

  const LS_DOMAIN_KEY = 'set-domain';
  const MOBILE_PREVIEW_DEFAULT_FIT = true;

  export default {
    components: {
      DesktopSvg,
      MobileSvg,
      PageRenameModal,
      DeviceSelectorList,
      NewCampaign,
      EditorGlobalSettings,
      EditorComponentSettings,
      EditorPages,
      DeleteFields,
      AddNewStyle,
      EditCustomStyle,
      DeleteCustomStyle,
      ManageField,
      InputValidations,
      CustomFontUpload,
      CustomFontUploadV2,
      CouponCreation,
      ImageManager: () => import('@/editor/components/modals/ImageManager.vue'),
      ImageManagerV2: () => import('@/editor/components/modals/ImageManagerV2.vue'),
      ImageCropper: () => import('@/editor/components/ImageCropper.vue'),
      ProductTour: () => import('@/editor/components/modals/ProductTour.vue'),
      ImageReplace: () => import('@/editor/components/modals/ImageReplace.vue'),
      MarginPopover: () => import('@/components/Editor/MarginPopover.vue'),
    },
    mixins: [upgradePlanModal, localStorage, popover],
    data() {
      return {
        selectedSize: { key: 'iphone_se', value: 'iPhone SE (375x667)' },
        mobilePhoneType: [
          { key: 'iphone_se', value: 'iPhone SE (375x667)' },
          { key: 'iphone_xr', value: 'iPhone XR (414x896)' },
          { key: 'iphone_12_pro', value: 'iPhone 12 Pro (390x844)' },
          { key: 'iphone_14_pro_max', value: 'iPhone 14 Pro Max (430x932)' },
          { key: 'pixel_7', value: 'Pixel 7 (412x915)' },
          { key: 'samsung_galaxy_s8_plusz', value: 'Samsung Galaxy S8+ (360x740)' },
          { key: 'samsung_galaxy_s20_ultra', value: 'Samsung Galaxy S20 Ultra (412x915)' },
        ],
        dimensions: {
          iphone_se: { width: '375px', height: '667px' },
          iphone_xr: { width: '414px', height: '896px' },
          iphone_12_pro: { width: '390px', height: '844px' },
          iphone_14_pro_max: { width: '430px', height: '932px' },
          pixel_7: { width: '412px', height: '915px' },
          samsung_galaxy_s8_plusz: { width: '360px', height: '740px' },
          samsung_galaxy_s20_ultra: { width: '412px', height: '915px' },
        },
        MockLayouts,
        dragStarting: false,
        dragTarget: null,
        hasTemplateData: false,
        saveType: null,
        showOverlay: false,
        redirecting: false,
        variantName: null,
        newVariant: false,
        inputWidth: 50,
        globalSettingsClosed: true,
        productTourVisible: false,
        mustBeShow: false,
        getMobileType: 'iPhone SE',
        getMobileDimension: '375x667',
        isResized: false,
        disabledResizeButton: true,
        getPhoneFrameHeight: 667,
        marginPopover: null,
      };
    },
    computed: {
      ...mapState([
        'usedFonts',
        'calendar',
        'boxes',
        'campaign',
        'customCss',
        'customJs',
        'customJsByEvents',
        'editMode',
        'isActiveUndo',
        'isActiveRedo',
        'mobilePreview',
        'selectedPage',
        'saveCompleted',
        'showGlobalLoader',
        'templateHash',
        'templateSaveData',
        'validationError',
        'teaserPreview',
        'pages',
        'mockSiteLayout',
        'selectedElement',
        'selectedRow',
        'selectedColumn',
        'globalStyle',
        'account',
      ]),
      ...mapGetters([
        'installedFonts',
        'pageCount',
        'isActiveShopifyDomain',
        'isActiveShoprenterDomain',
        'isSubUser',
        'whiteLabelling',
        'databaseId',
        'isEmbedded',
        'teaserPage',
        'hasTemplateFeature',
        'hasAccountFeature',
        'isNewTemplate',
        'isUniversal',
        'isTeaserPage',
        'nestedAccess',
      ]),
      position: {
        get() {
          return this.nestedAccess('globalStyle.overlay.mobilePosition');
        },
      },
      teaserPosition: {
        get() {
          return this.teaserPage?.data.mobilePosition;
        },
      },
      classes() {
        return {
          'globals-closed': this.globalSettingsClosed,
          'globals-opened': !this.globalSettingsClosed,
          'page-pane-closed':
            this.isNewTemplate && this.isUniversal && !this.mustBeShow && !this.mobilePreview,
          'page-pane-opened':
            !this.isNewTemplate || this.isNewTemplate || !this.isUniversal || this.mustBeShow,
        };
      },
      imageManagerVisible() {
        return this.$store.state.showImageManager;
      },
      imageManagerV2Visible() {
        return this.$store.state.showImageManagerV2;
      },
      imageCropperVisible() {
        return this.$store.state.imageCropper.show;
      },
      campaignRootUrl() {
        return `${this.campaign.omUrl}/${this.campaign.lang}/profile/campaign`;
      },
      campaignListUrl() {
        return `${this.campaignRootUrl}/list`;
      },
      campaignViewUrl() {
        return `${this.campaignRootUrl}/${this.campaign.campaignId}/view`;
      },
      campaignUpdateUrl() {
        return `${this.campaignRootUrl}/${this.campaign.campaignId}/update`;
      },
      selectedLanguage() {
        if (this.$i18n.locale === 'en') {
          return 'https://support.optimonk.com/hc/en-us/articles/360000682429-New-Editor-FAQ';
        }
        return 'https://support.optimonk.hu/hc/hu/articles/360003870432';
      },
      floatingActionsPosition() {
        const pos = this.$store.state.floatingActionsPosition;
        return {
          [pos]: '2.815rem',
        };
      },
      loaderShown() {
        return this.$store.state.showAdminLoader;
      },
      sidebarKey() {
        if (this.selectedPage && this.selectedPage.isTeaser) {
          return 'teaser-sidebar';
        }

        return 'general-sidebar';
      },
      iconName() {
        return this.isResized ? 'search-plus' : 'search-minus';
      },
      windowHeight() {
        const navbarHeight = 48;
        return window.innerHeight - navbarHeight;
      },
    },
    watch: {
      selectedElement(newValue, oldValue) {
        if (newValue?.uid !== oldValue?.uid && this.$refs.marginPopover) {
          this.$refs.marginPopover.onClose();
          this.marginPopover = null;
        }
      },
      mobilePreview(newValue) {
        const phoneFrame = this.$refs.phoneFrame;
        if (newValue === false) {
          if (!phoneFrame) return;
          phoneFrame.style.width = null;
          phoneFrame.style.height = null;
          phoneFrame.style.top = null;
          phoneFrame.style.transform = null;
          this.isResized = false;
        } else {
          this.setFrameStyles(this.selectedSize.key);
          this.isResized = false;
          this.distanceFromWorkspaceBottom();
          this.$nextTick(() => {
            if (MOBILE_PREVIEW_DEFAULT_FIT) this.transformIframe();

            if (this.isTeaserPage) {
              this.scrollToPosition(this.teaserPosition);
            } else {
              this.scrollToPosition(this.position);
            }
          });
        }
      },
      isTeaserPage(newValue) {
        if (newValue === false) {
          this.scrollToPosition(this.position);
        } else {
          this.scrollToPosition(this.teaserPosition);
        }
      },
      position(newValue) {
        this.scrollToPosition(newValue);
      },

      'teaserPage.data.mobilePosition': {
        handler(newValue) {
          if (newValue) {
            this.scrollToPosition(newValue);
          }
        },
        immediate: true,
        deep: true,
      },
      selectedSize(newValue) {
        if (this.mobilePreview) {
          this.calculateMobileType(newValue.value);
          this.calculateMobileDimension(newValue.value);
          this.setFrameStyles(newValue.key);

          const phoneFrame = this.$refs.phoneFrame;
          phoneFrame.style.top = null;
          phoneFrame.style.transform = null;
          this.isResized = false;

          this.phoneFrameHeight();
          this.distanceFromWorkspaceBottom();

          if (MOBILE_PREVIEW_DEFAULT_FIT) this.transformIframe();

          if (this.isTeaserPage) {
            this.scrollToPosition(this.teaserPosition);
          } else {
            this.scrollToPosition(this.position);
          }
        }
      },
      isUniversal: {
        handler(universal) {
          if (universal && this.isNewTemplate) {
            setTimeout(() => {
              window.om.bus.$emit('show-left-sidebar-content', 0);
            }, 500);
          }
        },
        immediate: true,
      },
      'globalStyle.palette': {
        handler(v) {
          const wrapperElement = document.querySelector('.om-palette-colors');
          const styleElement = createPaletteStyleElement(wrapperElement, 'editor-palette-colors');
          generatePalette(styleElement, [v.mainColor, ...v.secondaryColors], '');
        },
        immediate: true,
        deep: true,
      },
      customCss(value) {
        this.$bus.$emit('setWorkspaceStateAttr', {
          attr: 'customCss',
          stateAttr: 'template',
          value,
        });
      },
      customJsByEvents(value) {
        this.$bus.$emit('setWorkspaceStateAttr', {
          attr: 'customJsByEvents',
          stateAttr: 'template',
          value,
        });
      },
      showGlobalLoader(value) {
        // save template on first load of new variant after load complete
        if (value === false && this.newVariant === true) {
          this.newVariant = false;
          this.saveTemplate(false);
        }
      },
      // Todo
      // 'campaign.variantName' (variantName) {
      //   this.variantName = variantName
      //   this.updateInputWidth(variantName.length)
      // },
      '$i18n.locale': function (value) {
        document.querySelector('html').setAttribute('lang', value);
        const iframeHTML = frame().contentDocument.querySelector('html');
        if (iframeHTML) {
          iframeHTML.setAttribute('lang', value);
        }
        this.$bus.$emit('changeLocale', value);
      },
      installedFonts(n) {
        if (!n.length) return;
        loadAdditionalFonts({ store: this.$store });
      },
      validationError(v) {
        if (v) {
          this.$modal.hide('save-or-discard-changes');
        }
      },
      account() {
        this.initProductTour();
      },
    },
    created() {
      document.addEventListener('click', () => {
        this.hideColorPicker();
      });
    },
    beforeDestroy() {
      console.log('Cleaning up bus event handlers.');
      History.reset();
      Object.keys(this.$bus._events).forEach((event) => {
        if (event === 'cleared') {
          return;
        }
        const handlers = this.$bus._events[event];

        if (Array.isArray(handlers)) {
          handlers.forEach((h) => this.$bus.$off(event, h));
        }
      });
      this.$bus.$emit('cleared');
      this.$bus.$off('setMarginPopover');
      this.setTemplate(null);
    },
    mounted() {
      this.$bus.$on('selectFirstButton', () => {
        const firstElement = window.om.template.elements.find(
          ({ type }) => type === ELEMENTS.OmButton || type === ELEMENTS.OmText,
        );
        this.$bus.$emit('selectElement', firstElement.uid);
      });

      if (this.mobilePreview) {
        this.transformIframe();
      }

      window.addEventListener('scroll', this.scrollHandler);
      this.$bus.$on('openRightSidebar', (value) => {
        this.mustBeShow = value;
        if (!value) {
          track('editor_click', {
            location: 'workspace',
            setting: 'click',
            change: 'right-sidebar-close',
            ...getContextProperties(),
          });
        }
      });

      document.querySelector('.om-workspace-container').addEventListener('click', () => {
        if (!this.mobilePreview) return;
        if (this.isTeaserPage) {
          this.activatePaneL2('TeaserPane');
          this.deselectAll();
          return;
        }

        this.deselectAll();
        this.$bus.$emit('overlayClick');
      });

      hotkeys('ctrl+z, cmd+z', () => {
        this.undo();
        return false;
      });

      hotkeys('ctrl+y, cmd+shift+z, cmd+y', () => {
        this.redo();
        return false;
      });

      this.$bus.$on(
        'historySave',
        _debounce(() => {
          History.save();
          this.$bus.$emit('updateMaxPageHeight');
        }, 500),
      );

      const lastReportedColor = { property: null, at: Date.now() };

      this.$bus.$on('userInputChange', ({ property, value }) => {
        // WYSIWYG color change
        const isGlobalStyle = property.startsWith('globalStyle.');
        const isColumn = property.startsWith('selectedColumn.');
        const isRow = property.startsWith('selectedRow.');
        const isPage = property.startsWith('page.');
        const isTeaser = property.indexOf('.tab.') > -1;
        const isColor = property.indexOf('.color');

        // Don't report rapid color changes - like dragging
        if (isColor) {
          const isTimeDiffSmall = Date.now() - lastReportedColor.at < 5000;
          if (property === lastReportedColor.property && isTimeDiffSmall) {
            return;
          }
          lastReportedColor.property = property;
          lastReportedColor.at = Date.now();
        }

        if (property === 'omcolor') return;
        let component;
        if (isTeaser) {
          component = 'Teaser';
        } else if (isGlobalStyle) {
          component = 'Overlay';
        } else if (isColumn) {
          component = 'Column';
        } else if (isRow) {
          component = 'Row';
        } else if (isPage) {
          component = 'Page';
        } else {
          const selectedElement = this.$store.state.selectedElement;
          if (selectedElement) {
            component = selectedElement.type.replace('Om', '');
          } else {
            component = 'OmTeaser';
          }
        }
        const selectedPage = _get(this.$store.state, 'selectedPage', null);
        let pageIndex = -1;
        let pageName = 'No page selected';

        if (selectedPage) {
          pageIndex = window.om.template.elements
            .filter((el) => el.type === 'OmPage')
            .findIndex((el) => el.uid === selectedPage.uid);
          pageName = _get(selectedPage, 'data.title', 'No page title found');
        }

        property = property
          .replace('selectedElement.', '')
          .replace('globalStyle.', '')
          .replace('page.', '')
          .replace('style.', '')
          .replace('data.', '');

        if (process.env.NODE_ENV !== 'development') {
          track('test-ChangeEditorSetting', {
            component,
            setting: property,
            pageIndex,
            pageName,
            value,
          });
        }
      });

      this.$bus.$on('historyUndo', () => {
        this.undo();
      });
      this.$bus.$on('historyRedo', () => {
        this.redo();
      });
      this.$bus.$on('showUpgradePlanModal', () => {
        this.showUpgradePlanModal(['unbranded']);
      });
      this.$bus.$on('feature-for-feedback', (feature) => {
        this.$modal.show('feature-for-feedback', feature);
      });

      this.$bus.$on('showFontManager', () => this.$modal.show('font-management'));
      this.$bus.$on('smart-tag-modal', (params) => this.$modal.show('smart-tag-modal', params));
      this.$bus.$on('checkShopConnection', () => {
        const shopify = this.isCampaignAssignedToShopify();
        const shoprenter = this.isCampaignAssignedToShoprenter();
        this.$bus.$emit('checkShopConnectionResult', { shopify, shoprenter });
      });

      if (this.needsToSetDomain()) {
        this.$nextTick(() => {
          this.$modal.show('name-campaign', { mode: 'update' });
        });
      }
      this.$bus.$on('showLeftSidebarPopover', this.showPopover);
      this.$bus.$on('showImageCropper', (settings) =>
        this.$store.dispatch('showImageCropper', settings),
      );
      this.$bus.$on('setMarginPopover', (data) => {
        this.marginPopover = data;
      });
    },

    destroyed() {
      hotkeys.unbind('ctrl+s, cmd+s');
      hotkeys.unbind('ctrl+y, cmd+shift+z, cmd+y');
    },
    methods: {
      ...mapMutations([
        'activatePaneL2',
        'activatePaneL3',
        'backPane',
        'hideColorPicker',
        'changeCalendarVisibility',
        'changeFormManagerVisibility',
        'deselectAll',
        'setSelectedElement',
        'setSelectedColumn',
        'selectPage',
        'setSelectedRow',
        'updateWholeGlobalStyle',
        'updatePage',
        'setStateAttr',
        'showImageManager',
        'showFormManager',
        'setSelectedPath',
        'saveThemeKit',
        'setImageManagerDestination',
        'hideImageManager',
        'setUserInfoMessage',
        'setFonts',
        'setFontStatus',
        'setTeaserText',
        'showAdminLoader',
        'setSelectedPage',
        'setTeaserPage',
        'updateSettings',
        'modifyElementByUid',
        'setTemplate',
      ]),
      ...mapActions([
        'setEditorPreviewMode',
        'emitAccountFeatures',
        'saveAutomaticCouponConfig',
        'removeAutomaticCouponConfig',
        'showColorPicker',
      ]),
      scrollToPosition(positionType) {
        const phoneFrame = this.$refs.phoneFrame;
        const workspaceContainer = this.$refs.workspaceContainer;
        const scrollContainerHeight = workspaceContainer.offsetHeight;
        const phoneFrameHeight = phoneFrame.offsetHeight;
        const canScroll = phoneFrameHeight + 48 > scrollContainerHeight;

        if (canScroll && positionType?.includes('center')) {
          const scrollTo = (phoneFrameHeight - scrollContainerHeight) / 2 + 40;
          this.scrollWorkspace(workspaceContainer, scrollTo);
        } else if (canScroll && positionType === 'bottom') {
          this.scrollWorkspace(workspaceContainer, scrollContainerHeight);
        } else {
          this.scrollWorkspace(workspaceContainer, 0);
        }
      },
      scrollWorkspace(workspaceContainer, scrollTo) {
        workspaceContainer.scrollTo({
          top: scrollTo,
          behavior: 'instant',
        });
      },
      phoneFrameHeight() {
        this.getPhoneFrameHeight = this.$refs.phoneFrame?.offsetHeight;
      },
      distanceFromWorkspaceBottom() {
        const phoneFrame = this.getPhoneFrameHeight + 20;
        const workspace = this.$refs.workspace;

        if (phoneFrame > this.windowHeight - 40) {
          const bottomMargin = 80 + phoneFrame - this.windowHeight;
          workspace.style.marginBottom = `${bottomMargin}px`;
          this.disabledResizeButton = false;
        } else {
          workspace.style.marginBottom = null;
          this.disabledResizeButton = true;
        }
      },
      setMobilePreviewRealSize() {
        const phoneFrame = this.$refs.phoneFrame;

        phoneFrame.style.transform = null;
        phoneFrame.style.top = null;

        this.distanceFromWorkspaceBottom();
        if (this.isTeaserPage) {
          this.scrollToPosition(this.teaserPosition);
        } else {
          this.scrollToPosition(this.position);
        }
        this.isResized = false;
      },
      setMobilePreviewFitToScreen() {
        const phoneFrame = this.$refs.phoneFrame;
        const phoneHeight = this.getMobileDimension.split('x')[1];
        const scaleFactor =
          this.windowHeight / (phoneFrame.contentWindow.document.body.offsetHeight * 1.15);

        phoneFrame.style.transform = `scale(${scaleFactor})`;

        if (phoneHeight) {
          phoneFrame.style.top = `-${(phoneHeight - phoneHeight * scaleFactor) / 2}px`;
        } else {
          phoneFrame.style.top = `-${
            (this.getPhoneFrameHeight - this.getPhoneFrameHeight * scaleFactor) / 2
          }px`;
        }

        this.isResized =
          phoneFrame.contentWindow.document.body.offsetHeight > this.windowHeight - 80;
      },
      transformIframe() {
        const phoneFrame = this.$refs.phoneFrame;
        const workspace = this.$refs.workspace;

        if (this.isResized) {
          this.setMobilePreviewRealSize();
        } else if (phoneFrame.contentWindow.document.body.offsetHeight > this.windowHeight - 80) {
          this.setMobilePreviewFitToScreen();
          workspace.style.marginBottom = null;
        }

        this.$bus?.$emit?.('setWorkspaceStateAttr', {
          attr: 'scaledPreview',
          value: !!this.isResized,
        });
      },
      setFrameStyles(size) {
        const phoneFrame = this.$refs.phoneFrame;
        if (!phoneFrame) return;

        const dimensions = this.dimensions[size];
        if (dimensions) {
          phoneFrame.style.width = dimensions.width;
          phoneFrame.style.height = dimensions.height;
          if (+dimensions.height > this.windowHeight - 80) {
            this.disabledResizeButton = false;
          } else {
            this.disabledResizeButton = true;
          }
        }
      },
      calculateMobileType(type) {
        this.getMobileType = type?.split('(')[0].trim();
      },
      calculateMobileDimension(type) {
        this.getMobileDimension = type?.split('(')[1].replace(')', '').trim();
      },
      setCustomEditorTrigger(value) {
        this.setStateAttr({ attr: 'showCustomEditorBox', value });
      },
      updateInputWidth(length) {
        this.inputWidth = length > 3 ? length * 12 : 48;
      },
      variantNameUpdate(e) {
        const value = e.target.value;
        this.updateInputWidth(value.length);
        this.$bus.$emit('setWorkspaceStateAttr', { attr: 'updatedVariantName', value });
      },
      undo() {
        if (this.isActiveUndo) {
          History.undo();
          this.$bus.$emit('updateMaxPageHeight');
        }
      },
      redo() {
        if (this.isActiveRedo) {
          History.redo();
          this.$bus.$emit('updateMaxPageHeight');
        }
      },
      iframeLoaded() {
        // GlobalStype panes: CloseButtonPane, OverlayPane
        this.$bus.$on('activatePane', ({ pane, level }) => {
          if (level === 2) {
            this.activatePaneL2(pane);
          } else {
            this.activatePaneL3(pane);
          }
        });
        this.$bus.$on('openWebSiteOverlay', () => {
          window.om.bus.$emit('show-left-sidebar-content', {
            buttonIndex: 1,
            accordion: 'websiteOverlay',
            withoutCloseCase: true,
            closeOthers: true,
          });
        });
        this.$bus.$on('openThemeColors', () => {
          window.om.bus.$emit('show-left-sidebar-content', 0);
        });
        this.$bus.$on('couponRedeemAvailable', this.reportShopifyCouponRedeem);
        this.$bus.$on('hideColorPicker', this.hideColorPicker);
        this.$bus.$on('hideCalendar', () => this.changeCalendarVisibility('hidden'));
        this.$bus.$on('setSelectedPage', this.setSelectedPage);
        this.$bus.$on('setTeaserPage', this.setTeaserPage);
        this.$bus.$on('setSelectedRow', this.setSelectedRow);
        this.$bus.$on('setSelectedColumn', this.setSelectedColumn);
        this.$bus.$on('setSelectedElement', this.setSelectedElement);
        this.$bus.$on('sendPage', this.updatePage);
        this.$bus.$on('showImageManager', this.showImageManager);
        this.$bus.$on('setSelectedPath', this.setSelectedPath);
        this.$bus.$on('changeFormManagerVisibility', this.changeFormManagerVisibility);
        this.$bus.$on('setImageManagerDestination', this.setImageManagerDestination);
        this.$bus.$on('setEditorStateAttr', this.setStateAttr);
        this.$bus.$on('showAdminLoader', this.showAdminLoader);
        this.$bus.$on('showColorPicker', this.showColorPicker);
        this.$bus.$on('saveThemeKit', () => {
          this.saveThemeKit();
        });
        this.$bus.$on('sendGlobalStyle', this.updateWholeGlobalStyle);
        this.$bus.$on('updateSettings', this.updateSettings);
        this.$bus.$on('hideModals', () => {
          this.changeFormManagerVisibility({ hide: 'couponSettings' });
          this.changeFormManagerVisibility({ hide: 'lumiraSettings' });
          this.changeFormManagerVisibility({ hide: 'firstStep' });
          this.changeFormManagerVisibility({ hide: 'secondStep' });
          this.changeFormManagerVisibility({ hide: 'thirdStep' });
          this.changeFormManagerVisibility({ hide: 'deleteFields' });
          this.changeFormManagerVisibility({ hide: 'manageField' });
          this.changeFormManagerVisibility({ hide: 'inputValidation' });
          this.changeFormManagerVisibility({ hide: 'customHTMLSettings' });
          this.changeFormManagerVisibility({ hide: 'addThemeColor' });
          this.changeFormManagerVisibility({ hide: 'couponCreate' });
          this.changeFormManagerVisibility({ hide: 'previewMissing' });
          this.changeFormManagerVisibility({ hide: 'previewOld' });
          this.hideImageManager();
        });
        this.$bus.$on('changeLocale', (locale) => {
          if (this.$i18n) this.$i18n.locale = locale;
        });
        this.$bus.$emit('requestGlobalStyle');
        this.$bus.$on('setUserInfoMessage', (transKey) => this.setUserInfoMessage(transKey));
        this.$bus.$on('setFonts', this.setFonts);
        this.$bus.$on('setPages', this.setPages);
        this.$bus.$on('saveAutomaticCouponConfig', this.saveAutomaticCouponConfig);
        this.$bus.$on('removeAutomaticCouponConfig', this.removeAutomaticCouponConfig);

        const { baseTemplate, id: variantId } = this.$route.params;
        const token = getQueryVariable('token');
        if (token) {
          apollo
            .mutate({
              mutation: gql`
                mutation ($token: String!) {
                  createVariantFromTemplate(token: $token) {
                    variantId
                    newVariant
                    token
                  }
                }
              `,
              variables: { token },
            })
            .then((resp) => {
              const { variantId, newVariant, token } = resp.data.createVariantFromTemplate;
              localStorage.omEditorToken = token;
              this.newVariant = newVariant;
              this.$router.replace({ name: 'variant', params: { variantId } });
              this.hasTemplateData = true;
              const payload = {
                attr: 'templateSaveData',
                value: { type: 'variant', value: variantId },
              };
              this.$bus.$emit('setWorkspaceStateAttr', payload);
              this.setStateAttr(payload);
            })
            .catch((error) => console.error(error));
        } else if (baseTemplate || variantId) {
          const payload = { attr: 'templateSaveData' };
          if (baseTemplate) {
            payload.value = { type: 'base', value: baseTemplate };
            this.saveType = 'base';
          }
          if (variantId) payload.value = { type: 'variant', value: variantId };
          this.hasTemplateData = true;
          this.$bus.$emit('setWorkspaceStateAttr', payload);
          this.setStateAttr(payload);
        }
        this.$bus.$on('showNotification', ({ type, transKey, transParams }) => {
          this.$notify({
            type,
            text: this.$t(transKey, transParams),
          });
        });
        this.$bus.$on('setFontStatus', this.setFontStatus);
        this.$bus.$on('setTeaserText', this.setTeaserText);
        this.$bus.$emit('setWorkspaceStateAttr', { attr: 'isSubUser', value: this.isSubUser });
        this.$bus.$emit('setWorkspaceStateAttr', {
          attr: 'whiteLabel',
          value: this.whiteLabelling,
        });
        this.emitAccountFeatures();
      },
      reportShopifyCouponRedeem() {
        if (!window.Intercom) return;

        const isShopifyCamp = this.isCampaignAssignedToShopify();

        if (!isShopifyCamp) return;

        window.Intercom('trackEvent', 'Coupon redeem ready');
      },
      isCampaignAssignedToShopify() {
        let active = false;
        if (this.campaign.domain) {
          active = this.isActiveShopifyDomain(this.campaign.domain);
        }

        return active;
      },
      isCampaignAssignedToShoprenter() {
        let active = false;

        if (this.campaign.domain) {
          active = this.isActiveShoprenterDomain(this.campaign.domain);
        }

        return active;
      },
      saveTemplate(v = true) {
        this.$bus.$emit('saveTemplate', v);
      },
      next() {
        this.$bus.$emit('saveTemplate', { nextButton: true });
        this.redirecting = true;
        this.goToCampaign(1000);
      },
      goToCampaignView() {
        window.location = this.campaignViewUrl;
      },
      goToCampaign(timeout = 0) {
        setTimeout(() => {
          if (this.validationError.property === undefined) {
            window.location = this.campaignUpdateUrl;
          }
        }, timeout);
      },
      overlayHandler(v) {
        this.showOverlay = v;
      },
      setPages() {
        const store = frameStore();
        const pages = store && store.getters.pages;

        this.setStateAttr({ attr: 'pages', value: pages });
      },
      initProductTour() {
        if (
          !this.isEmbedded &&
          new Date(this.account.createdAt) >= new Date('2021-06-01') &&
          !_get(this.account, 'profile.productTours.editorUITourExperiment') &&
          !this.needsToSetDomain()
        ) {
          this.productTourVisible = true;
          setTimeout(() => this.$modal.show('product-tour'), 100);
        } else {
          this.$modal.hide('product-tour');
        }
      },
      onDomainUpdated() {
        this.lsRemove(LS_DOMAIN_KEY);
        this.initProductTour();
      },
      needsToSetDomain() {
        const lsData = this.lsRetrieve(LS_DOMAIN_KEY);
        if (lsData) {
          const { campaignId } = this.$route.params;

          if (campaignId && lsData === campaignId) {
            return true;
          }
        }
        return false;
      },
      toggleGlobalSettings() {
        this.globalSettingsClosed = !this.globalSettingsClosed;
        window.parent.om.bus.$emit('closeSideBar');
      },
      onClose() {
        this.marginPopover = null;
      },
    },
  };
</script>

<style lang="sass" src="@/editor/sass/main.sass"></style>
<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .om-workspace-container
    -webkit-overflow-scrolling: touch
    -webkit-backface-visibility: hidden
    backface-visibility: hidden
    scrollbar-color: transparent
    scrollbar-width: thin
    &::-webkit-scrollbar
      height: 7px
    &::-webkit-scrollbar-track
      background: transparent
    &::-webkit-scrollbar-thumb
      background: $om-gray-300
      border-radius: 10px
    &::-webkit-scrollbar-thumb:hover
      background: $om-gray-400
  .om-mobile-workspace
    position: absolute
    left: 0
    right: 0
  .mobile-size-chooser
    justify-content: flex-end
    position: absolute
    right: 20px
    top: 20px
    z-index: 10
    @media screen and (max-height: 575px)
      right: 270px
  .mobile-size-select
    margin: auto
    max-width: 350px
    .form-control
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0
      border-bottom: 0
    .btn
      border-top-left-radius: 0
      border-top-right-radius: 0
    .select-is-focused
      box-shadow: none !important
      border-color: transparent !important
      background-color: $om-gray-300 !important
    .popper:not(.select-popper)
      transform: translate3d(110px, 78px, 0) !important
      left: auto !important
      right: 100px
      > span
        white-space: nowrap
    .empty-tooltip
      .popper
        padding: 0 !important
        min-width: auto
  .mobile-info
    min-width: 100px
  .om-workspace-container
    &.hide-text-selection
      background-color: #B9BEC6
  .om-mobile-frame-new
    background-repeat: no-repeat
    height: 100%
    width: 100%
    margin: 0 auto
    padding: 0 3.125rem
    background-color: transparent
    position: relative
    text-align: center
    #workspaceFrame
      height: 667px
      width: 375px
      border: 0
      border-radius: 0
      margin-top: 20px
      margin-left: auto
      margin-right: auto

  .page-structure
    &-wrapper
      padding: 0
      padding-right: .5rem
    &-description
      padding-left: 0
      padding-right: .375rem
  .editor-wrapper
    position: inherit
    width: 100%
    .sidebar
      max-width: 340px
      width: auto
      .sidebar
        flex: 1
    .popup
      width: auto
      flex: 1
    .editor-right-column
      flex: 1
    &.globals-opened
      &.page-pane-closed
        .editor
          position: relative
          &.sidebar
            transition: all 0.3s ease-in-out
          .sidebar
            transition: all 0.3s ease-in-out
        .editor-right-column
          max-width: 0
          transition: all 0.3s ease-in-out
    &.globals-opened
      &.page-pane-opened
        .editor
          &.sidebar
            transition: all 0.3s ease-in-out
          .sidebar
            transition: all 0.3s ease-in-out
        .editor-right-column
          width: 100%
          transition: all 0.3s ease-in-out
    &.globals-closed
      &.page-pane-closed
        .editor
          &.sidebar
            max-width: 72px
            transition: all 0.3s ease-in-out
          .sidebar
            flex: 0
            min-width: 0
            transition: all 0.3s ease-in-out
        .editor-right-column
          max-width: 0
          transition: all 0.3s ease-in-out
    &.globals-closed
      &.page-pane-opened
        .editor
          &.sidebar
            max-width: 72px
            transition: all 0.3s ease-in-out
          .sidebar
            flex: 0
            min-width: 0
            transition: all 0.3s ease-in-out
        .editor-right-column
          width: 100%
          position: relative
          transition: all 0.3s ease-in-out
  .left-column-toggle
    display: flex
    position: absolute
    top: calc(50% - 5rem)
    width: 1rem
    height: 5rem
    background: white
    border-radius: 0px 12px 12px 0px
    flex: none
    z-index: 1
    justify-content: center
    align-items: center
    &:hover
      cursor: pointer
  .select-dimensions
    .popper.select-popper
      left: auto !important
      right: 0
</style>

<style lang="sass" scoped>
  .save-or-discard-changes-btn-box
    .brand-btn
      width: 100%
  .mobile-view
    height: 100%
    background: #edeff1

  .disable
    opacity: 0.3

  .disable:hover
    opacity: 0.3

  .custom-editor-overlay
    position: absolute
    width: 100vw
    height: 100vh
    z-index: 1

  .editor-wrapper
    .popup:not(.mobile-view) .om-workspace-container
      position: relative
    .om-workspace-container
      height: calc(100vh - 3rem)
</style>
